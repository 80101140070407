//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { subscribe } from '@/notifications'
import { defaultLogger } from '@/loggers'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

export default {
  name: 'NotificationBar',
  components: {
    'close-icon': createSVGComponent({ icon: 'close', size: 16 }),
  },
  data () {
    return {
      step: 1,
    }
  },
  created () {
    this.appName = window.FEATURES.app_title
  },
  methods: {
    close () {
      this.$emit('close')

      try {
        window.localStorage.setItem('notification-bar-closed', 'true')
      } catch (e) {
        defaultLogger.warn(`[${this.$options.name}.close]`, e)
      }
    },
    next () {
      this.step++
      subscribe(true)
    },
  },
}
